<template>
  <div class="uploadFile">
    <upload-files :phase="2" />
    <img src="../assets/Intersection1.png" class="footer-upload" />
    <loader v-if="this.getLoadingUploadFileCdg" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadFiles from './component/UploadFiles.vue';
export default {
  name: 'Insertion-BDD',
  components: {
    loader: () => import('./Loader'),
    UploadFiles
  },
  computed: {
    ...mapGetters(['getLoadingUploadFileCdg'])
  }
};
</script>

<style scoped lang="scss">
.uploadFile {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  background-color: #f5f4fa;
  border-radius: 19px;
  margin: 5px 25px 23px 22px;
  height: calc(100% - 74px);
  justify-content: center;
  align-items: center;
  display: flex;
  .footer-upload {
    width: 100%;
    bottom: 0px;
    position: absolute;
    z-index: 0;
  }
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.test::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.test::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.test::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.swal2-actions {
  .swal2-confirm {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 9px;
    padding: 7px 15px;
    border: none;
    width: 50px;
    margin-right: 5px;
    outline: none;
  }
  .swal2-cancel {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
    width: 50px;
  }
  .swal2-deny {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
    width: 50px;
  }
}
</style>
