<template>
  <div class="box-white" @change="handleUploadFile">
    <div class="box-title-uplaod" v-if="showDate">
      <span class="text">Choisissez votre date</span>
    </div>
    <div class="box-filter-upload" v-if="showDate">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Année</span>
        </div>

        <b-form-select
          class="b-form-select-new-style bg-select"
          v-model="year"
          :options="ListYear"
        ></b-form-select>
      </div>
    </div>
    <div class="box-title-uplaod mt-4">
      <span class="text">Importer</span>
    </div>
    <b-row align-h="center">
      <div class="container" @drop="handleFileDrop">
        <div class="file-wrapper">
          <input
            type="file"
            name="file-input"
            id="uploadInput"
            :class="{ display: this.getLoadingUploadFileCdg }"
            @change="handleFileInput"
          />
          <div class="upload_label">
            <img src="@/assets/Icon feather-upload.png" class="icon-upload" />
            <p class="drag-title mb-0">Drag & Drop to insert</p>
            <p class="drag-title mt-0 mb-0">or</p>
            <div class="btn-upload-file">Browse File</div>
          </div>
          <div class="upload_label">
            <p class=" drag-title" v-if="this.file">
              {{ this.file.name }}
            </p>
          </div>
        </div>
      </div>
    </b-row>
    <div class="file-exel-style"><span>*</span> Fichier Excel</div>
    <b-modal
      ref="uploadFileCdg"
      title="Resultat d'injection"
      :hide-footer="true"
      :modal-class="
        computedWidthModalDetails
          ? 'modalResultatInjectionAll'
          : 'modalResultatInjection'
      "
      no-close-on-backdrop
      @hidden="resetModalResultInjection"
    >
      <div
        v-if="getLoadingUploadFileCdg == true"
        class="loading-custom-template-block"
      >
        <Half-circle-spinner
          :animation-duration="1000"
          :size="50"
          :color="'#704ad1'"
          class="loeder"
        />
      </div>
      <div class="d-flex justify-content-center mb-3">
        <b-button
          class="button-valide-style mr-4"
          @click.prevent.stop="injection"
          :disabled="getLoadingUploadFileCdg"
        >
          <span>
            Confirmer
            <div class="loading ml-2"></div>
          </span>
        </b-button>
      </div>
      <div class="row justify-content-between">
        <div
          class="ml-3 mr-3"
          v-if="
            getReturnDataInjection &&
              getReturnDataInjection.undecided &&
              getReturnDataInjection.undecided.length
          "
        >
          <span class="title-modal-injection">Liste des dossiers existe :</span>
          <b-table-simple
            class="table-fiche m-0 p-0 d-flex justify-content-start block-details-injection tableInjection"
            responsive
          >
            <b-tbody>
              <b-tr class="title-ligne">
                <b-th class="newcolor"></b-th>
                <b-th class="newcolor"></b-th>
                <b-th class="newcolor"
                  ><b-form-checkbox
                    :disabled="checkAll == 'ignore'"
                    v-model="checkAll"
                    value="ignore"
                    @change="changeAllType"
                  ></b-form-checkbox
                ></b-th>
                <b-th class="newcolor width-some-colomns"
                  ><b-form-checkbox
                    v-model="checkAll"
                    value="replace"
                    @change="changeAllType"
                    :disabled="checkAll == 'replace'"
                  ></b-form-checkbox
                ></b-th>
                <b-th class="newcolor"
                  ><b-form-checkbox
                    v-model="checkAll"
                    value="version"
                    @change="changeAllType"
                    :disabled="checkAll == 'version'"
                  ></b-form-checkbox
                ></b-th>
              </b-tr>
              <b-tr class="title-ligne">
                <b-th class="newcolor"></b-th>
                <b-th class="newcolor">Numéro de dossier</b-th>
                <b-th class="newcolor">Ignoré</b-th>
                <b-th class="newcolor width-some-colomns">Remplacé</b-th>
                <b-th class="newcolor">Versionné</b-th>
              </b-tr>
              <template
                v-for="(item, index) in getReturnDataInjection.undecided"
              >
                <b-tr :key="'frais' + index + item.id">
                  <b-td class="newcolor"
                    ><font-awesome-icon
                      icon="eye"
                      @click="openModelDetails(item)"
                  /></b-td>
                  <b-td class="newcolor">{{ item.numero_dossier }}</b-td>
                  <b-td class="newcolor"
                    ><b-form-checkbox
                      v-model="item.decision"
                      value="ignore"
                      @change="checkDecision()"
                      :disabled="item.decision == 'ignore'"
                    ></b-form-checkbox
                  ></b-td>
                  <b-td class="newcolor"
                    ><b-form-checkbox
                      v-model="item.decision"
                      value="replace"
                      @change="checkDecision()"
                      :disabled="item.decision == 'replace'"
                    ></b-form-checkbox
                  ></b-td>
                  <b-td class="newcolor"
                    ><b-form-checkbox
                      v-model="item.decision"
                      @change="checkDecision()"
                      :disabled="item.decision == 'version'"
                      value="version"
                    ></b-form-checkbox
                  ></b-td>
                </b-tr>
              </template>
              <b-tr v-if="getReturnDataInjection.undecided.length == 0">
                <b-td> Il n'y a aucun enregistrement à afficher</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

        <div
          class="block-details-injection ml-3 mr-3"
          v-if="
            getReturnDataInjection &&
              getReturnDataInjection.new &&
              getReturnDataInjection.new.length
          "
        >
          <span class="title-modal-injection"
            >Liste des dossiers a injecté :
          </span>
          <div
            v-for="(i, index) in getReturnDataInjection.new"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
        <div
          class="block-details-injection ml-3 mr-3"
          v-if="
            getReturnDataInjection &&
              getReturnDataInjection.no_organisme &&
              getReturnDataInjection.no_organisme.length
          "
        >
          <span class="title-modal-injection"
            >Liste des dossiers sans organisme :
          </span>
          <div
            v-for="(i, index) in getReturnDataInjection.no_organisme"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>

        <div
          class="block-details-injection ml-3 mr-3"
          v-if="
            getReturnDataInjection &&
              getReturnDataInjection.no_installateur &&
              getReturnDataInjection.no_installateur.length
          "
        >
          <span class="title-modal-injection"
            >Liste des dossiers sans installateur :
          </span>
          <div
            class="ml-2"
            v-for="(i, index) in getReturnDataInjection.no_installateur"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
        <div
          class="block-details-injection ml-3 mr-3"
          v-if="
            getReturnDataInjection &&
              getReturnDataInjection.no_agent_commercial &&
              getReturnDataInjection.no_agent_commercial.length
          "
        >
          <span class="title-modal-injection"
            >Liste des dossiers que leurs agents commerciaux n'existe pas :
          </span>
          <div
            v-for="(i, index) in getReturnDataInjection.no_agent_commercial"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modelDetails"
      title="Historique de dossier"
      :hide-footer="true"
      no-close-on-backdrop
      modal-class="modalHistoriqueDossier"
      size="lg"
      @hidden="resetModalDetails"
    >
      <b-table-simple
        v-if="selectedItem != null"
        class="table-fiche m-0 p-0 d-flex justify-content-center custom-table-historique-upload"
        responsive
      >
        <b-tbody>
          <b-tr class="title-ligne">
            <b-th class="newcolor">Numéro de dossier</b-th>
            <b-th class="newcolor">Nom</b-th>
            <b-th class="newcolor">Prénom</b-th>
            <b-th class="newcolor">Regie</b-th>
            <b-th class="newcolor">Numéro de lot</b-th>
            <b-th class="newcolor">Nom de dépot</b-th>
            <b-th class="newcolor" v-if="phase == 2">Super régie</b-th>
            <b-th class="newcolor">Installateur</b-th>
            <b-th class="newcolor">Organisme</b-th>
            <b-th class="newcolor">Montant</b-th>
            <b-th class="newcolor ">Montant HT</b-th>
            <b-th class="newcolor">Coef prime installateur </b-th>
            <b-th class="newcolor">Dossier ID</b-th>
            <b-th class="newcolor">kwh cumac</b-th>
            <b-th class="newcolor">Date dépôt de dossier</b-th>
            <b-th class="newcolor" v-if="phase == 2">Bureau d'etude</b-th>
            <b-th class="newcolor">Version</b-th>
          </b-tr>
          <template v-for="(item, i) in selectedItem.projects">
            <b-tr :key="'index_selected_dossier' + i">
              <b-td class="newcolor">{{ item.numero_dossier }}</b-td>
              <b-td class="newcolor">{{ item.nom1 }}</b-td>
              <b-td class="newcolor">{{ item.prenom1 }}</b-td>
              <b-td class="newcolor">{{ item.regie }}</b-td>
              <b-td class="newcolor">{{ item.numero_de_lot }}</b-td>
              <b-td class="newcolor">{{ item.numero_de_depot }}</b-td>
              <b-td class="newcolor" v-if="phase == 2">{{
                item.super_regie
              }}</b-td>
              <b-td class="newcolor">{{ item.installeur }}</b-td>
              <b-td class="newcolor">{{ item.organisme }}</b-td>
              <b-td class="newcolor">{{ item.montant_final }}</b-td>
              <b-td class="newcolor ">{{ item.montant_total_ht }}</b-td>
              <b-td class="newcolor">{{ item.coef_prime_installateur }}</b-td>
              <b-td class="newcolor">{{ item.dossier_ID }}</b-td>
              <b-td class="newcolor">{{ item.kwh_cumac }}</b-td>
              <b-td class="newcolor">{{ item.date_depot_dossier }}</b-td>
              <b-td class="newcolor" v-if="phase == 2">{{ item.BE_TYPE }}</b-td>
              <b-td class="newcolor">{{ item.version }}</b-td>
            </b-tr>
          </template>
          <template>
            <b-tr>
              <b-td class="newcolor font-weight-bold"
                ><span>{{ selectedItem.new.numero_dossier }}</span></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{ 'red-color-style': selectedItem.new.nom1_diff }"
                  >{{ selectedItem.new.nom1 }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{ 'red-color-style': selectedItem.new.prenom1_diff }"
                  >{{ selectedItem.new.prenom1 }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{ 'red-color-style': selectedItem.new.regie_diff }"
                  >{{ selectedItem.new.regie }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.numero_de_lot_diff
                  }"
                  >{{ selectedItem.new.numero_de_lot }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.numero_de_depot_diff
                  }"
                  >{{ selectedItem.new.numero_de_depot }}</span
                ></b-td
              >

              <b-td class="newcolor font-weight-bold" v-if="phase == 2"
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.super_regie_diff
                  }"
                  >{{ selectedItem.new.super_regie }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.installeur_diff
                  }"
                  >{{ selectedItem.new.installeur }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.organisme_diff
                  }"
                  >{{ selectedItem.new.organisme }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.montant_final_diff
                  }"
                  >{{ selectedItem.new.montant_final }}</span
                ></b-td
              >
              <b-td class="font-weight-bold newcolor  "
                ><span
                  :class="{
                    'red-color-style': selectedItem.new.montant_total_ht_diff
                  }"
                  class="font-weight-bold"
                  >{{ selectedItem.new.montant_total_ht }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style':
                      selectedItem.new.coef_prime_installateur_diff
                  }"
                  >{{ selectedItem.new.coef_prime_installateur }}</span
                ></b-td
              >

              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.dossier_ID_diff
                  }"
                  >{{ selectedItem.new.dossier_ID }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.kwh_cumac_diff
                  }"
                  >{{ selectedItem.new.kwh_cumac }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.date_depot_dossier_diff
                  }"
                  >{{ selectedItem.new.date_depot_dossier }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold" v-if="phase == 2"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.BE_TYPE_diff
                  }"
                  >{{ selectedItem.new.BE_TYPE }}</span
                ></b-td
              >
              <b-td class="newcolor font-weight-bold"
                ><span
                  class="font-weight-bold"
                  :class="{
                    'red-color-style': selectedItem.new.version_diff
                  }"
                  >{{ selectedItem.new.version }}</span
                ></b-td
              >
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-modal>
    <b-modal
      ref="modelResultInjection"
      title="Résultat finale"
      :hide-footer="true"
      no-close-on-backdrop
      :modal-class="computedWidthModalResult"
      size="lg"
      @hidden="resetModalResult"
    >
      <div class="row" v-if="result != null">
        <div
          class="block-injection-result "
          v-if="result && result.warning && result.warning.length"
        >
          <span class="title-modal-injection"
            >Liste des dossiers que leurs agents commerciaux n'existe pas ({{
              result.warning.length
            }}):
          </span>
          <div
            v-for="(i, index) in result.warning"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection">
              {{ i.numero }} : {{ i.reason }}</span
            >
          </div>
        </div>
        <div
          class="block-injection-result "
          v-if="result && result.ignore && result.ignore.length"
        >
          <span class="title-modal-injection"
            >Liste des dossiers ignoré ({{ result.ignore.length }}):
          </span>
          <div v-for="(i, index) in result.ignore" :key="'succes-file' + index">
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection">
              {{ i.numero }} : {{ i.reason }}</span
            >
          </div>
        </div>
        <div
          class="block-injection-result "
          v-if="result && result.new && result.new.length"
        >
          <span class="title-modal-injection"
            >Liste des nouveaux dossiers injecté ({{ result.new.length }}):
          </span>
          <div v-for="(i, index) in result.new" :key="'succes-file' + index">
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
        <div
          class="block-injection-result "
          v-if="result && result.replace && result.replace.length"
        >
          <span class="title-modal-injection"
            >Liste des dossiers remplacé ({{ result.replace.length }}):
          </span>
          <div
            v-for="(i, index) in result.replace"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
        <div
          class="block-injection-result "
          v-if="result && result.version && result.version.length"
        >
          <span class="title-modal-injection"
            >Liste des dossiers avec une nouvelle version ({{
              result.version.length
            }}) :
          </span>
          <div
            v-for="(i, index) in result.version"
            :key="'succes-file' + index"
          >
            <span class="mr-2">•</span>
            <span class="item-in-modal-injection"> {{ i }}</span>
          </div>
        </div>
      </div></b-modal
    >
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import moment from 'moment';
export default {
  props: { showDate: { default: false }, phase: { required: true } },
  data() {
    return {
      file: null,
      year: null,
      ListYear: [],
      selectedItem: null,
      result: null,
      checkAll: 'ignore'
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingUploadFileCdg',
      'getReturnDataInjection',
      'getErrorUploadFileCdg'
    ]),
    computedWidthModalDetails() {
      if (this.getReturnDataInjection) {
        let count = 0;
        if (
          this.getReturnDataInjection.undecided &&
          this.getReturnDataInjection.undecided.length
        )
          count = count + 1;
        if (
          this.getReturnDataInjection.new &&
          this.getReturnDataInjection.new.length
        )
          count = count + 1;
        if (
          this.getReturnDataInjection.no_organisme &&
          this.getReturnDataInjection.no_organisme.length
        )
          count = count + 1;
        if (
          this.getReturnDataInjection.no_installateur &&
          this.getReturnDataInjection.no_installateur.length
        )
          count = count + 1;
        if (
          this.getReturnDataInjection.no_agent_commercial &&
          this.getReturnDataInjection.no_agent_commercial.length
        )
          count = count + 1;
        if (count > 2) return true;
        return false;
      } else {
        return false;
      }
    },
    computedWidthModalResult() {
      let count = 0;
      if (this.result) {
        if (this.result.ignore && this.result.ignore.length) count = count + 1;
        if (this.result.new && this.result.new.length) count = count + 1;
        if (this.result.replace && this.result.replace.length)
          count = count + 1;
        if (this.result.version && this.result.version.length)
          count = count + 1;
      }

      if (count == 1) {
        return 'modalHistoriqueDossierV1 modalHistoriqueDossier';
      } else if (count == 2) {
        return 'modalHistoriqueDossierV2 modalHistoriqueDossier';
      } else if (count == 3) {
        return 'modalHistoriqueDossierV3 modalHistoriqueDossier';
      } else if (count == 4) {
        return 'modalHistoriqueDossierV4 modalHistoriqueDossier';
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapActions([
      'uploadFile',
      'uploadFileTh',
      'CheckuploadFileTh',
      'uploadFileCdg'
    ]),
    ...mapMutations(['SET_ERROR']),
    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    },
    async handleUploadFile() {
      if (this.file !== null) {
        const payload = {
          file: this.file,
          phase: this.phase
        };
        if (this.showDate) {
          payload.annee = this.year;
        }
        const response = await this.uploadFileCdg(payload);
        if (response.succes) {
          // this.$swal.fire('', 'Fichier uploadée avec succes', 'success');
          this.result = response.data;
          this.$refs['modelResultInjection'].show();
          // if (this.phase == 2) {
          //   this.$router.push('/rapportTh');
          // }
        } else {
          if (this.getErrorUploadFileCdg?.missing) {
            let missing = this.getErrorUploadFileCdg.missing
              ? '<div><h4><i>Colonnes manquantes :</i></h4>'
              : '';
            if (this.getErrorUploadFileCdg.missing) {
              for (const [key, value] of Object.entries(
                this.getErrorUploadFileCdg.missing
              )) {
                missing = missing + '<li>' + value + '</li>';
              }
              missing = missing + '</div>';
            }

            let htmlContent = "<div style='display:flex'>" + missing + '</div>';

            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              html: htmlContent,
              allowOutsideClick: false,
              showCloseButton: true
            });
          } else if (this.getErrorUploadFileCdg?.error) {
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              html: this.getErrorUploadFileCdg?.error,
              allowOutsideClick: false,
              showCloseButton: true
            });
          } else if (this.getErrorUploadFileCdg?.error406) {
            this.$refs['uploadFileCdg'].show();
          }
        }
      }
    },
    async injection() {
      const payload = {
        file: this.file,
        phase: this.phase
      };
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided &&
        this.getReturnDataInjection.undecided.length
      ) {
        payload.decisions = this.getReturnDataInjection.undecided.map(item => {
          return { numero: item.numero_dossier, decision: item.decision };
        });
      }
      const response = await this.uploadFileCdg(payload);
      if (response.succes) {
        this.result = response.data;
        this.$refs['uploadFileCdg'].hide();
        this.$refs['modelResultInjection'].show();
        // if (this.phase == 2) {
        //   this.$router.push('/rapportTh');
        // }
      }
    },
    openModelDetails(item) {
      this.selectedItem = item;
      this.$refs['modelDetails'].show();
    },
    changeAllType() {
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided
      ) {
        this.getReturnDataInjection.undecided.map(
          item => (item.decision = this.checkAll)
        );
      }
    },
    checkDecision() {
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided
      ) {
        if (
          this.getReturnDataInjection.undecided.filter(
            item => item.decision == this.checkAll
          ).length != this.getReturnDataInjection.undecided.length
        )
          this.checkAll = null;
      }
    },
    resetModalResult() {
      this.result = null;
      this.file = null;
    },
    resetModalResultInjection() {
      this.checkAll = 'ignore';
    },
    resetModalDetails() {
      this.selectedItem = null;
    }
  },

  mounted() {
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.year = courantYear;
    this.SET_ERROR(null);
  }
};
</script>

<style lang="scss">
.box-white {
  z-index: 50;
  width: fit-content;
  padding: 20px 20px 25px 0px;
  background: #ffffff;
  border-radius: 17px;
  width: 50%;
  top: -5%;
  position: relative;
  text-align: initial;
  .box-title-uplaod {
    position: relative;
    background-color: #f5f5ff;
    width: 215px;
    padding: 7px 23px;
    border-radius: 0px 18px 18px 0px;
    text-align: end;
    margin-bottom: 26px;
    .text {
      font-size: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      color: #5d5d5d;
    }
  }
  .container {
    height: 187px;
    width: 380px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px dashed #704ad1;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 40px;
        position: relative;
        top: 32px;
        .drag-title {
          font-size: 15px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #704ad1;
        }
        p {
          font-size: 15px;
        }
        .icon-upload {
          width: 33px;
          height: 33px;
          margin-bottom: 15px;
        }
        .btn-upload-file {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 400;
          width: 60%;
          border-radius: 29px;
          background-color: #704ad1;
          padding: 11px 20px;
          border: 0;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
  .file-exel-style {
    font-size: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #5d5d5d;
    position: relative;
    right: 23%;
    text-align: end;
    margin-top: 10px;
    span {
      font-weight: 500;
      color: #fe808b;
    }
  }
  .error-upload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    text-align: center;
    bottom: -2%;
    right: 3%;
    z-index: 50;
    color: red;
    .title-error {
      font-weight: 600;
    }
  }
}
.block-details-injection {
  height: calc(100vh - 210px);
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c2e6;
    border-radius: 7px;
  }
}
.block-injection-result {
  height: calc(100vh - 145px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c2e6;
    border-radius: 7px;
  }
}
.tableInjection {
  td,
  th {
    padding: 5p;
    min-width: 100px !important;
  }
}
.title-modal-injection {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 5px;
}
.item-in-modal-injection {
  font-size: 12px;
  font-weight: 700;
}
</style>
<style lang="scss">
.modalResultatInjection {
  .modal-dialog {
    width: 80% !important;
    max-width: 80% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.modalResultatInjectionAll {
  .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.modalHistoriqueDossierV1 {
  .modal-dialog {
    width: 50% !important;
    max-width: 50% !important;
  }
  .block-injection-result {
    padding: 10px;
    width: 100%;
  }
}
.modalHistoriqueDossierV2 {
  .modal-dialog {
    width: 65% !important;
    max-width: 65% !important;
  }
  .block-injection-result {
    width: 50%;
    padding: 10px;
  }
}
.modalHistoriqueDossierV3 {
  .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
  }
  .block-injection-result {
    width: 33%;
    padding: 10px;
  }
}
.modalHistoriqueDossierV4 {
  .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
  }
  .block-injection-result {
    width: 25%;
    padding: 10px;
  }
}
@media (min-width: 576px) {
  .modalResultatInjection .modal-dialog {
    width: 70% !important;
    max-width: 70% !important;
    min-height: calc(100vh - 89px);
    margin-bottom: 10px;

    .modal-content {
      min-height: calc(100vh - 89px);
    }
  }
  .modalResultatInjectionAll .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
    min-height: calc(100vh - 89px);
    .modal-content {
      min-height: calc(100vh - 89px);
    }
  }
  .modalHistoriqueDossier {
    min-height: calc(100vh - 50px);
    .modal-content {
      min-height: calc(100vh - 50px);
    }
    .modal-dialog {
      margin: auto;
    }
  }
  .modalHistoriqueDossierV1 .modal-dialog {
    width: 50% !important;
    max-width: 50% !important;
  }
  .modalHistoriqueDossierV2 .modal-dialog {
    width: 65% !important;
    max-width: 65% !important;
  }
  .modalHistoriqueDossierV3 .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
  }
  .modalHistoriqueDossierV4 .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
  }
}
.custom-table-historique-upload .table {
  display: inline-table !important;
}
</style>
